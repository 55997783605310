export const translate = {
    'en-GB' : require('./en-GB.json'),
    'sq-AL' : require('./sq-AL.json'),
    'de-DE' : require('./de-DE.json'),
    // 'fr-FR' : require('./en-GB.json'),
    // 'it-IT' : require('./en-GB.json'),
    // 'es-ES' : require('./en-GB.json'),
    // 'tr-TR' : require('./en-GB.json'),
    // 'pr-PR' : require('./en-GB.json'),
    // 'rs-RS' : require('./en-GB.json'),
    // 'jp-JP' : require('./en-GB.json'),
    // 'ch-CH' : require('./en-GB.json')
};
