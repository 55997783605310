import React from 'react'
import './loading.scss'

const Loading = () => {
    return (
      <div className='loading-animation'>
          <div className="circle circle1"></div>
          <div className="circle circle2"></div>
          <div className="circle circle3"></div>
      </div>
    )
}

export default Loading