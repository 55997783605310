import { lazy, Suspense, useEffect } from 'react';
import {
  
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Loading from './components/shared/Loading/Loading';
import ScrollToTop from './ScrollToTop';
import './App.scss';
import Aos from 'aos';
import "aos/dist/aos.css";
import {IntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';
import {translate} from './translation/translate';
import NotFound from './pages/NotFound/NotFound';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { chooseLanguage } from './store/actions/languageAction';




const Home = lazy(() => import('./pages/Home/Home'));
const Signup = lazy(() => import('./pages/Signup/Signup'));
const Login = lazy(() => import('./pages/Login/Login'));
const Workshops = lazy(() => import('./pages/Workshops/Workshops'));
const OutkosAcademySchool = lazy(() => import('./pages/OutkosAcademySchool/OutkosAcademySchool'));
const Workshop = lazy(() => import('./pages/Workshop/Workshop'));
const ApplyOnline = lazy(() => import('./pages/ApplyOnline/ApplyOnline'));
const Apps = lazy(() => import('./pages/Apps/Apps'));
const AllCourses = lazy(() => import('./pages/AllCourses/AllCourses'));
const HelpCenter = lazy(() => import('./pages/HelpCenter/HelpCenter'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const KosovaKids = lazy(() => import('./pages/KosovaKids/KosovaKids'));
const SuccessStories = lazy(() => import('./pages/SuccessStories/SuccessStories'));
const KidsWorkshops = lazy(() => import('./pages/KidsWorkshops/KidsWorkshops'));
const Resources = lazy(() => import('./pages/Resources/Resources'));
const OurInstructors = lazy(() => import('./pages/OurInstructors/OurInstructors'));
const Blog = lazy(() => import('./pages/Blog/Blog'));
const Jobs = lazy(() => import('./pages/Jobs/Jobs'));
const Courses = lazy(() => import('./pages/Courses/Courses'));
const Shop = lazy(() => import('./pages/Shop/Shop'));
const Apply = lazy(() => import('./pages/Apply/Apply'));
const Apply1 = lazy(() => import('./pages/Apply/Apply'));
const Submitted = lazy(() => import('./pages/Apply/Submitted'));
const ApplyNow = lazy(() => import('./pages/ApplyNow/ApplyNow'));
const Partnerships = lazy(() => import('./pages/Partnerships/Partnerships'));
const Community = lazy(() => import('./pages/Community/Community'));
const BecomePartner = lazy(() => import('./pages/BecomePartner/BecomePartner'));
const ReferFriend = lazy(() => import('./pages/ReferFriend/ReferFriend'));
const Projects = lazy(() => import('./pages/Projects/Projects'));
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const BecomeInstructor = lazy(() => import('./pages/BecomeInstructor/BecomeInstructor'));
const Challenges = lazy(() => import('./pages/Challenges/Challenges'));
const Challenge = lazy(() => import('./pages/Challenge/Challenge'));
const Blogs = lazy(() => import('./pages/Blogs/Blogs'));
const Blogs2 = lazy(() => import('./pages/Blogs2/Blogs2'));
const Blogs3 = lazy (() => import("./pages/Blogs3/Blogs3"))
const BlogWrapper = lazy (() => import("./pages/Blogss/Blogss"))
const ApplyWorkshop = lazy (() => import("./pages/ApplyWorkshops/Apply"))
const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"))
const SubmittedNewsLeetter = lazy (()=> import("./pages/Apply/SubmittedNesLetter"))
function App() {

 
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();


  const location = useLocation();
  useEffect(() => {
    Aos.init({
      duration:1250,
      once: true
    })
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const languageFromUrl = searchParams.get('language');
    if (languageFromUrl) {
      const regionCode = languageFromUrl.replace(/^sq-/, '');
      dispatch(chooseLanguage(language));
    }
  }, [dispatch, location.search]);
const { id, category2, title1 } = useParams();

  return (
    
    
      <Suspense fallback={<Loading />}>
        <IntlProvider locale={language}
          // formats={{ number: 'en' }}
          messages={translate[language]}
        >
          <ScrollToTop />
          <Routes>
          <Route path="/:language?" element={<Navigate to={`/${language}`} replace />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/Signup/" element={<Signup />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Workshops" element={<Workshops />} />
            <Route path="/OutkosAcademySchool" element={<OutkosAcademySchool />} />
            <Route path='/Workshop/:to1' element={<Workshop />} /> 
            <Route path="/ApplyOnline" element={<ApplyOnline />} />
            <Route path="/Apps" element={<Apps />} />
            <Route path="/AllCourses" element={<AllCourses />} />
            <Route path="/AllCourses/page/:page" element={<AllCourses />} />
            <Route path="/AllCourses/:currentPage/:englishTitle" element={<AllCourses />} />
            <Route path="/AllCourses/:currentPage/:queryString" element={<AllCourses />} />
           
            <Route path="/HelpCenter" element={<HelpCenter />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/KosovaKids" element={<KosovaKids />} />
            <Route path="/SuccessStories" element={<SuccessStories />} />
            <Route path="/KidsWorkshops" element={<KidsWorkshops />} />
            <Route path="/Resources" element={<Resources />} />
            <Route path="/OurInstructors" element={<OurInstructors />} />
            <Route path="/blog/:id/:category/:year1/:month/:pageNumber" element={<Blog />} />
            <Route path='/blog/:title' element ={<Blog/>}/>
            <Route path="/Blog/:year1/:month/:pageNumber" element={<Blog />} />
            <Route path="/Blog/:selectedCategory/:year1/:selectMonth/:pages" element={<Blog />} />
            <Route path="/Blog/:page" element={<Blog />} />
            <Route path="/Blog/:selectedCategory/:page" element={<Blog />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path='/Jobs/:to1' element={<Jobs />} /> 
            <Route path='/Courses/:slug1/:slug' element={<Courses />} /> 
            <Route path="/Shop" element={<Shop />} />
            <Route path='/Apply/:slug/' element={<Apply />} /> 
            <Route path='/submitted' element={<Submitted />} /> 
            <Route path='/ApplyNow' element={<ApplyNow />} /> 
            <Route path='/Partnerships' element={<Partnerships />} /> 
            <Route path='/Community' element={<Community />} /> 
            <Route path='/BecomePartner' element={<BecomePartner />} /> 
            <Route path='/ReferFriend' element={<ReferFriend />} /> 
            <Route path='/Projects' element={<Projects />} /> 
            <Route path='/category/:englishTitle' element={<Projects/>}/>
            <Route path='/AboutUs' element={<AboutUs />} /> 
            <Route path='/BecomeInstructor' element={<BecomeInstructor />} /> 
            <Route path='/Challenges' element={<Challenges />} /> 
            <Route path='/Challenge/:title' element={<Challenge />} /> 
            <Route path='/blogs/:category2/:id/:title1'  element={<BlogWrapper /> } /> 
            <Route path='/Blogs/:category/:id/:title1' element={<Blogs2 />} /> 
            <Route path = '/ApplyWorkshop/:to1' element= {<ApplyWorkshop/>}/>
            <Route path='/ForgotPassword' element = {<ForgotPassword/>}/>
            <Route path='/SubmittedNewsLetter' element = {<SubmittedNewsLeetter/>}/>

            <Route path="*" element={<NotFound />}/>
          </Routes>
        </IntlProvider>
      </Suspense>
    
  );
}

export default App;
