import React from 'react'
import './notFound.scss'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const NotFound = () => {
    return (
        <div className='not-found-page'>
            <div className='container'>
                <div className='inline'>
                    <h1>4</h1>
                    <div className='shared-halfLogo'></div>
                    <h1>4</h1>
                </div>
                <p>              
                    <FormattedMessage id='notFound-text1' 
                        defaultMessage='We are sorry but this page is not available on our website.' /> 
                </p>
                <Link to='/'>
                    <FormattedMessage id='back-to-homepage' 
                        defaultMessage='Back to homepage' />
                </Link>
            </div>
        </div>
    )
}

export default NotFound